<template>
  <section>
    <div class="row">
      <div class="col-12 d-flex mb-3">
        <div class="table-box p-3">
          <div class="border-bottom pb-3"></div>
          <div class="row pb-3 pt-3">
            <div class=" col-md-12 col-sm-12 col-12 pb-3">
            <button  class="btn btn-primary" @click="openModal('showModalOne')">
                Create New language 
            </button>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <select
                class="form-select"
                aria-label="Language-select"
                v-model="selectedLanguage"
              >
                <option
                  v-for="lang in languages"
                  :key="lang.id"
                  :value="lang.language"
                >
                  {{ lang.name }}
                </option>
              </select>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="input-group search-box">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="translations?.search"
                  v-model="searchQuery"
                />
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="searchList"
                >
                  <font-awesome-icon
                    :icon="['fas', 'search']"
                  ></font-awesome-icon>
                </button>
              </div>
            </div>
          </div>
          <data-table
            id="translation-data-table"
            :translations="translations"
            :options="options"
            :data-set="data.dataList"
            :page-size="data.pageSize"
            @change-action="changeAction"
            @pagination="pagination"
            @sorting="sorting"
            @limit-change="limitChange"
          />
        </div>
      </div>
    </div>
  </section>

  <div
    class="modal fade"
    id="showModal"
    tabindex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ translations?.translation }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div ref="resetForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-3">
                <label class="form-control-label">{{ translations?.key }}</label>
                <Field
                  disabled
                  type="text"
                  class="form-control"
                  v-model="modalForm.key"
                  :class="{ 'border-danger': errors?.key }"
                  name="key"
                />
                <span class="text-danger" v-if="errors?.key">{{
                  errors?.key
                }}</span>
              </div>
              <div class="col-12 mb-3">
                <label class="form-control-label">{{ translations?.value }}</label>
                <Field
                  type="text"
                  class="form-control"
                  v-model="modalForm.value"
                  :class="{ 'border-danger': errors?.value }"
                  name="value"
                />
                <span class="text-danger" v-if="errors?.value">{{
                  errors?.value
                }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ translations?.close }}
            </button>
            <button type="submit" @click="submitData()" class="btn btn-primary">
              {{ translations?.submit }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="showModalOne"
    tabindex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Create new language </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div ref="resetForm">
         
            <div class="modal-body">
              <div class="row">
                <!-- Key Field -->
                <div class="col-12 mb-3">
                  <label class="form-control-label">Language Name  </label>
                  <Field
                    type="text"
                    class="form-control"
                    v-model="languageModalForm.new_language_name"
                    :class="{ 'border-danger': errors?.key }"
                    name="key"
                  />
                  <span class="text-danger" v-if="errors?.key">{{ errors?.key }}</span>
                </div>

                <!-- Value Field -->
                <div class="col-12 mb-3">
                  <label class="form-control-label">Language Locale </label>
                  <Field
                    type="text"
                    class="form-control"
                    v-model="languageModalForm.new_locale"
                    :class="{ 'border-danger': errors?.value }"
                    name="value"
                  />
                  <span class="text-danger" v-if="errors?.value">{{ errors?.value }}</span>
                </div>
              </div>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {{ translations?.close }}
              </button>
              <button type="submit" @click="createLanguage()" class="btn btn-primary">
                {{ translations?.submit }}
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Modal -->
</template>

<script>
import HelperFunction from "@/common/helpers";
import DataTable from "@/common/components/datatable";
import { onMounted, ref, computed, inject } from "vue";
import { Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
//import {useRoute} from "vue-router";
import axios from "axios";

export default {
  name: "TranslationComponent",
  components: {
    DataTable,
    Field,
  },
  setup() {
    const { data, openModal, getAllData, resetForm } = HelperFunction();
    //const route = useRoute();
    const locale = localStorage.getItem("locale") || "en";
    const searchQuery = ref("");
    const selectedLanguage = ref(locale ?? null);
    const store = useStore();
    const toast = inject('toast')
    
    const setParams = {
      locale: locale,
      search: searchQuery.value,
    };
    const translations = computed(() => {
        return store.state.translations;  
    });

    const modalForm = ref({
      id: null,
      key: "",
      value: "",
    });
    const languageModalForm = ref({
      new_language_name: "",
      new_locale: "",
    });
    data.setURL = "/translations-data";
    onMounted(async () => {
      await getAllData(data.setURL, setParams);
      fetchLanguages();
    });
    const options = {
      name: translations.value?.translation_list || "Translation List",
      columns: [
        {
          title: translations.value?.group || "Group",
          type: "text",
          key: "group",
          sort: true,
        },
        {
          title: translations.value?.key || "Key",
          type: "text",
          key: "key",
          sort: true,
        },
        {
          title: translations.value?.value || "Value",
          type: "text",
          key: "value",
          sort: true,
        },
      ],
      showAction: true,
      action: [
        {
          title: "Edit",
          icon: "edit",
          type: "edit",
          show: true,
        },
      ],
    };
    const languages = ref([]);
    const fetchLanguages = async () => {
      try {
        const response = await axios.get("/languages");
        languages.value = response.data;
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    const searchList = async () => {
      setParams.locale = selectedLanguage.value;
      setParams.search = searchQuery.value;
      await getAllData(data.setURL, setParams);
    };

    async function changeAction(action, row) {
      if (action.type === "edit") {
        modalForm.value.id = row.id;
        modalForm.value.key = row.key;
        modalForm.value.value = row.value;
        setTimeout(() => {
          $("#showModal").modal("show"); // eslint-disable-line
        }, 50);
      }
    }

    const pagination = (page) => {
      setParams.page = page;
      getAllData(data.setURL, setParams);
    };

    const limitChange = (limit) => {
      setParams.limit = limit;
      delete setParams.page;
      getAllData(data.setURL, setParams);
    };

    const sorting = (column) => {
      setParams.column = column.key;
      setParams.sort = setParams.sort === "asc" ? "desc" : "asc";
      getAllData(data.setURL, setParams);
    };

    async function submitData() {
      console.log(modalForm.value);
      try {
        const response = await axios.post(
          `/translation-data/${modalForm.value.id}`,
          {
            value: modalForm.value.value,
          }
        );
        toast.success(response.data.message);
        $("#showModal").modal("hide"); // eslint-disable-line
        await getAllData(data.setURL, setParams);
      } catch (error) {
        console.error("Error updating translation:", error.response.data);
      }
    }

    async function createLanguage() {

      try {
        const response = await axios.post(
          `/create-language-with-translation`,
          {
            new_language_name: languageModalForm.value.new_language_name,
            new_locale: languageModalForm.value.new_locale,

          }
        );
        console.log(response.data.message);
        $("#showModalOne").modal("hide"); // eslint-disable-line
        await getAllData(data.setURL, setParams);
      } catch (error) {
        console.error("Error updating translation:", error.response.data);
      }
    }

    const schema = Yup.object().shape({
      key: Yup.string().required("Key is required"),
      value: Yup.string().required("Value is required"),
      group: Yup.string().required("Group is required"),
    });



    return {
      openModal,
      changeAction,
      pagination,
      limitChange,
      sorting,
      submitData,
      data,
      options,
      schema,
      resetForm,
      searchQuery,
      fetchLanguages,
      searchList,
      selectedLanguage,
      modalForm,
      languages,
      translations,
      createLanguage,
      languageModalForm
    };
  },
};
</script>

<style scoped>
/* Custom styling for TranslationComponent */
</style>

<style scoped>
.panel {
  padding: 20px;
}
.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.panel-body {
  overflow-x: auto;
}
.button {
  padding: 5px 10px;
  background-color: #5c76fa;
  color: white;
  border: none;
  cursor: pointer;
}
.table th,
.table td {
  text-align: left;
  padding: 8px;
}
</style>
